// # Core
import React, { PureComponent } from 'react';

// # Redux
import { connect } from 'react-redux';
import { updateTerminalStacksList } from '../../redux/actions';

// # Components
import TerminalStack from './TerminalStack';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// # Instruments
import { stacksConfigurations } from '../../initialData';


class TerminalStacks extends PureComponent {
  reorderStacksList = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  onDragEnd = (result) => {
    if (result.destination) {
      const { dispatch, terminalStacksList } = this.props;

      const newTerminalStacksList = this.reorderStacksList(
        terminalStacksList,
        result.source.index,
        result.destination.index,
      );

      return dispatch(updateTerminalStacksList(newTerminalStacksList));
    }
  }

  render() {
    const { terminalStacksList } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              className="terminal__stacks"
              ref={provided.innerRef}
            >
              {terminalStacksList.map((stack, index) =>
                <TerminalStack
                  cellsNumber={stacksConfigurations[stack.type].cells}
                  key={`${stack}-${index}`}
                  index={index}
                  stackType={stack.type}
                />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}


const mapStateToProps = (state) => {
  const { terminalStacksList } = state.terminalData;

  return {
    terminalStacksList,
  };
};


export default connect(mapStateToProps)(TerminalStacks);
