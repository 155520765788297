// # Instruments
import { complectationsList } from '../../initialData';


const defaultState = {
  terminalStacksList: complectationsList.minimal.complectation,
  terminalHighlitedStackType: '',
  terminalLocationId: 'office',
  terminalComplectationId: 'minimal',
  terminalTheme: 'black', // gold, wood, silver
  terminalRunline: '',
  terminalLogo: {
    name: '',
    path: '',
  },
  isPopupVisible: false,
}

const terminalData = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_TERMINAL_STACKS_LIST':
      const terminalStacksList = (action.terminalStacksList) ? action.terminalStacksList : defaultState;
      return { ...state, terminalStacksList };

    case 'UPDATE_TERMINAL_HIGHLITED_STACK_TYPE':
      const terminalHighlitedStackType = (action.terminalHighlitedStackType) ? action.terminalHighlitedStackType : defaultState.terminalHighlitedStackType;
      return { ...state, terminalHighlitedStackType };

    case 'UPDATE_TERMINAL_LOCATION_ID':
      const terminalLocationId = (action.terminalLocationId) ? action.terminalLocationId : defaultState.terminalLocationId;
      return { ...state, terminalLocationId };

    case 'UPDATE_TERMINAL_COMPLECTATION_ID':
      const terminalComplectationId = (action.terminalComplectationId) ? action.terminalComplectationId : defaultState.terminalComplectationId;
      return { ...state, terminalComplectationId };

    case 'UPDATE_TERMINAL_THEME':
      const terminalTheme = (action.terminalTheme) ? action.terminalTheme : defaultState.terminalTheme;
      return { ...state, terminalTheme };

    case 'UPDATE_TERMINAL_RUNLINE':
      const terminalRunline = (action.terminalRunline) ? action.terminalRunline : defaultState.terminalRunline;
      return { ...state, terminalRunline };

    case 'UPDATE_TERMINAL_LOGO':
      const terminalLogo = (action.terminalLogo) ? action.terminalLogo : defaultState.terminalLogo;
      return { ...state, terminalLogo };

    case 'UPDATE_POPUP_VISIBILITY': 
      const isPopupVisible = (action.isPopupVisible) ? action.isPopupVisible : defaultState.isPopupVisible;
      return { ...state, isPopupVisible };

    default:
      return state;
  }
}


export default terminalData;
