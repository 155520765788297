export const complectationsList = {
  minimal: {
    id: 'minimal',
    name: 'Минимальная',
    complectation: [
      {
        type: 'control',
        creationNumber: 0,
      },
      {
        type: 'post-8',
        creationNumber: 0,
      }
    ],
  },
  business: {
    id: 'business',
    name: 'Бизнес',
    complectation: [
      {
        type: 'burger',
        creationNumber: 0,
      },
      {
        type: 'fresh',
        creationNumber: 0,
      },
      {
        type: 'vending-rfid-soft',
        creationNumber: 0,
      },
      {
        type: 'control',
        creationNumber: 0,
      },
      {
        type: 'post-9',
        creationNumber: 0,
      },
      {
        type: 'laundry',
        creationNumber: 0,
      },
      {
        type: 'burger',
        creationNumber: 1,
      }
    ],
  },
  premium: {
    id: 'premium',
    name: 'Премиум',
    complectation: [
      {
        type: 'vending-rfid-cold',
        creationNumber: 0,
      },
      {
        type: 'vending-rfid-soft',
        creationNumber: 0,
      },
      {
        type: 'burger',
        creationNumber: 0,
      },
      {
        type: 'fresh',
        creationNumber: 0,
      },
      {
        type: 'laundry',
        creationNumber: 0,
      },
      {
        type: 'post-8',
        creationNumber: 0,
      },
      {
        type: 'post-9',
        creationNumber: 0,
      },
      {
        type: 'control',
        creationNumber: 0,
      },
      {
        type: 'post-9',
        creationNumber: 1,
      },
      {
        type: 'post-24',
        creationNumber: 0,
      },
      {
        type: 'laundry',
        creationNumber: 1,
      },
      {
        type: 'fresh',
        creationNumber: 1,
      },
      {
        type: 'burger',
        creationNumber: 1,
      },
      {
        type: 'vending-rfid-soft',
        creationNumber: 1,
      },
      {
        type: 'vending-rfid-cold',
        creationNumber: 1,
      }
    ],
  },
  custom: {
    id: 'custom',
    name: 'Пользовательский',
  }
};

export const locationsList = {
  office: {
    id: 'office',
    title: 'Бизнес центр',
    image: 'office.jpg',
  },
  shop: {
    id: 'shop',
    title: 'Торговый центр',
    image: 'shop.jpg',
  },
  house: {
    id: 'house',
    title: 'Жилой комплекс',
    image: 'house.jpg',
  }
};

export const stacksConfigurations = {
  'control': {
    title: 'Terminal',
    type: 'Terminal',
    icon: 'stack-control',
    tooltip: 'Модуль управления',
  },
  'post-8': {
    marker: 'Post',
    title: 'Post 8',
    type: 'Post 8',
    icon: 'stack-post-8',
    tooltip: 'Модуль для отправки и получения посылок',
    cells: 8,
  },
  'post-9': {
    marker: 'Post',
    title: 'Post 9',
    type: 'Post 9',
    icon: 'stack-post-9',
    tooltip: 'Модуль для отправки и получения посылок',
    cells: 9,
  },
  'post-10': {
    marker: 'Post',
    title: 'Post 10',
    type: 'Post 10',
    icon: 'stack-post-10',
    tooltip: 'Модуль для отправки и получения посылок',
    cells: 10,
  },
  'post-24': {
    marker: 'Post',
    title: 'Post 24',
    type: 'Post 24',
    icon: 'stack-post-24',
    tooltip: 'Модуль для отправки и получения посылок',
    cells: 24,
  },
  'fresh': {
    marker: 'Fresh',
    title: 'Fresh<br/>(-18C/+6C)',
    type: 'Fresh',
    icon: 'stack-fresh',
    tooltip: 'Модуль заморозки и охлаждения',
    cells: 4,
  },
  'laundry': {
    marker: 'Laundry',
    title: 'Laundry',
    type: 'Laundry',
    icon: 'stack-laundry',
    tooltip: 'Модуль химчистки',
    cells: 4,
  },
  'burger': {
    marker: 'Burger',
    title: 'Burger<br/>(+40C/+60C)',
    type: 'Burger',
    icon: 'stack-burger',
    tooltip: 'Модуль с подогревом',
    cells: 6,
  },
  'vending-rfid-soft': {
    marker: 'Vending Soft',
    title: 'Vending<br/>RFID soft',
    type: 'Vending RFID soft',
    icon: 'stack-vending-soft',
    tooltip: 'Вендинг без температурного режима',
    cells: 3,
  },
  'vending-rfid-cold': {
    marker: 'Vending Cold',
    title: 'Vending<br/>RFID cold',
    type: 'Vending RFID cold',
    icon: 'stack-vending-cold',
    tooltip: 'Вендинг с охлаждением',
    cells: 3,
  },
}

export const terminalThemesList = {
  silver: {
    id: 'silver',
    name: 'Cosmic<br/>White',
    title: 'Cosmic white',
  },
  black: {
    id: 'black',
    name: 'Deep<br/>Graphite',
    title: 'Deep graphite',
  },
  wood: {
    id: 'wood',
    name: 'Dark<br/>Oak',
    title: 'Dark oak',
  },
  gold: {
    id: 'silver',
    name: 'Gold<br/>Metallic',
    title: 'Gold metallic',
  },
};