// # Core
import React, { PureComponent } from 'react';
import { string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';

// # Instruments
import { getUniqueID } from '../../helpers';


class TerminalRunline extends PureComponent {
  constructor() {
    super();

    this.state = {
      runlinesCount: 0,
      animationDelayConstant: 0,
    }

    this.runLineScreen = React.createRef();
    this.runLineString = React.createRef();
  }

  componentDidMount() {
    this.calculateRunlinesCountAndAnimationTiming();
  }

  componentDidUpdate() {
    this.calculateRunlinesCountAndAnimationTiming();
  }

  calculateRunlinesCountAndAnimationTiming = () => {
    const { runlinesCount } = this.state;

    const randomNumber = 12; // change this number to make space between runlines bigger or smaller
    const runlineMovementSpeedInPx = 50; // 50px per second

    const runlineScreenWidht = this.runLineScreen.current.offsetWidth;
    const runlineStringWidth = this.runLineString.current.offsetWidth - runlineScreenWidht;
    const fullAnimationPathWidth = runlineScreenWidht + runlineStringWidth;
    const runlineStringBlockWidth = runlineStringWidth + fullAnimationPathWidth / randomNumber;

    const runlines = Math.floor(fullAnimationPathWidth / runlineStringBlockWidth);
    const animationDuration = fullAnimationPathWidth / runlineMovementSpeedInPx;
    const animationDelayConstant = animationDuration / runlines;

    if (runlinesCount !== runlines) {
      return this.setState({ runlinesCount: runlines, animationDelayConstant, animationDuration });
    }
  }

  renderRunlineStrings = () => {
    const { terminalRunline } = this.props;
    const { runlinesCount, animationDelayConstant, animationDuration } = this.state;

    return (runlinesCount !== 0) ?
      Array(runlinesCount).fill(0).map((_, index) => {
        return <span
          className="terminal__ticker-string"
          style={{ animationDelay: animationDelayConstant * index + 's', animationDuration: animationDuration + 's'}}
          key={getUniqueID()}
        >
          {(terminalRunline.length !== 0) ? terminalRunline : 'Brandshub / innovated by Omnic'}
        </span>
      }) : null
  }

  render() {
    const { terminalRunline } = this.props;

    return (
      <>
        <div ref={this.runLineScreen} className="terminal__ticker">
          <span ref={this.runLineString} className="terminal__ticker-holder">
            {(terminalRunline.length !== 0) ? terminalRunline : 'Brandshub / innovated by Omnic'}
          </span>
          {this.renderRunlineStrings()}
        </div>
      </>
    );
  }
}


TerminalRunline.propTypes = {
  terminalRunline: string.isRequired,
}


const mapStateToProps = (state) => {
  const { terminalRunline, terminalStacksList } = state.terminalData;

  return {
    terminalRunline,
    terminalStacksList,
  };
};


export default connect(mapStateToProps)(TerminalRunline);
