// # Core
import React, { PureComponent } from 'react';
import { string } from 'prop-types';


class Icon extends PureComponent {
  render() {
    const { type } = this.props;

    switch (type) {
      case 'info':
        return (
          <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#9b9b9b" fillRule="evenodd" d="M7.5 14a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zm0 1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z" clipRule="evenodd"/>
            <path fill="#9b9b9b" d="M8.699 2.442c.66 0 .99.45.99.965 0 .643-.573 1.238-1.32 1.238-.626 0-.99-.37-.973-.981 0-.515.434-1.222 1.303-1.222zM6.666 12.3c-.521 0-.903-.321-.538-1.736l.598-2.51c.104-.402.121-.563 0-.563-.156 0-.833.278-1.233.552l-.26-.435C6.5 6.531 7.96 5.9 8.585 5.9c.52 0 .608.627.347 1.592l-.685 2.638c-.121.467-.07.627.052.627.157 0 .67-.193 1.173-.595l.295.402c-1.233 1.254-2.58 1.737-3.1 1.737z"/>
          </svg>
        );

      case 'movement-arrow':
        return (
          <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#93CC3E" d="M23.835 12.423l-3.48-3.48a.497.497 0 1 0-.702.703l2.63 2.631h-6.305a3.957 3.957 0 0 0-3.43-3.429V2.542l2.631 2.63a.495.495 0 0 0 .703 0 .497.497 0 0 0 0-.702L12.402.99a.497.497 0 0 0-.702 0L8.22 4.47a.497.497 0 1 0 .703.703l2.631-2.631v6.306a3.957 3.957 0 0 0-3.429 3.43H1.819l2.63-2.631a.497.497 0 1 0-.702-.703l-3.48 3.479a.497.497 0 0 0 0 .703l3.48 3.48a.495.495 0 0 0 .703 0 .497.497 0 0 0 0-.704l-2.631-2.63h6.306a3.957 3.957 0 0 0 3.43 3.429v6.306l-2.632-2.63a.497.497 0 1 0-.702.702l3.479 3.48a.495.495 0 0 0 .703 0l3.48-3.48a.497.497 0 1 0-.703-.703l-2.631 2.631v-6.306a3.957 3.957 0 0 0 3.429-3.43h6.306l-2.63 2.631a.497.497 0 1 0 .702.703l3.48-3.48a.497.497 0 0 0 0-.702zm-11.784 3.334a2.986 2.986 0 0 1-2.982-2.982 2.986 2.986 0 0 1 2.982-2.983 2.986 2.986 0 0 1 2.983 2.982 2.986 2.986 0 0 1-2.983 2.983z"/>
          </svg>
        );

      case 'trash-bucket':
        return (
          <svg viewBox="0 0 21 25" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#93CC3E" d="M20.423 3.093H14.01V1.961c0-.615-.5-1.116-1.115-1.116H8.432c-.615 0-1.115.5-1.115 1.116v1.132H.904a.561.561 0 1 0 0 1.123h1.041l.71 18.472a2.09 2.09 0 0 0 2.095 2.016h11.827a2.09 2.09 0 0 0 2.095-2.016l.71-18.472h1.04a.562.562 0 0 0 0-1.123zM8.44 1.968h4.447v1.125H8.44V1.968zm9.11 20.677a.97.97 0 0 1-.973.936H4.75a.97.97 0 0 1-.973-.936l-.708-18.43h15.19l-.709 18.43z"/>
            <path fill="#93CC3E" d="M7.244 9.306a.561.561 0 1 0-1.122.022l.176 9.163c.006.306.256.55.561.55h.011a.561.561 0 0 0 .55-.572l-.176-9.163zM10.663 8.756a.561.561 0 0 0-.561.561v9.163a.562.562 0 0 0 1.123 0V9.317a.562.562 0 0 0-.562-.561zM14.654 8.756a.562.562 0 0 0-.572.55l-.176 9.163c-.006.31.24.566.55.572h.012a.561.561 0 0 0 .56-.55l.177-9.163a.561.561 0 0 0-.55-.572z"/>
          </svg>
        );

      case 'stack-control':
        return (
          <svg viewBox="0 0 29 59" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#D8D8D8" fillOpacity=".31" d="M7 20h14v15H7V20z"/>
            <path fill="#fff" fillRule="evenodd" d="M.5.5h27.718v58.254H.5V.5zm1 1v56.254h25.718V1.5H1.5z" clipRule="evenodd"/>
            <path fill="#fff" fillRule="evenodd" d="M6.5 19.5h15v16h-15v-16zm1 1v14h13v-14h-13z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-post-8':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41z" clipRule="evenodd"/>
            <path fill="#fff" fillRule="evenodd" d="M5.5 32.5h32v7h-32v-7zm1 1v5h30v-5h-30zM5.5 41.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 73.5h32v11h-32v-11zm1 1v9h30v-9h-30zM5.5 23.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 49.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 57.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 65.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 9.5h32v12h-32v-12zm1 1v10h30v-10h-30z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-post-9':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41z" clipRule="evenodd"/>
            <path fill="#fff" fillRule="evenodd" d="M5.5 32.5h32v7h-32v-7zm1 1v5h30v-5h-30zM5.5 41.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 73.5h15v11h-15v-11zm1 1v9h13v-9h-13zM22.5 73.5h15v11h-15v-11zm1 1v9h13v-9h-13zM5.5 23.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 49.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 57.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 65.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 9.5h32v12h-32v-12zm1 1v10h30v-10h-30z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-post-10':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41z" clipRule="evenodd"/>
            <path fill="#fff" fillRule="evenodd" d="M5.5 32.5h32v7h-32v-7zm1 1v5h30v-5h-30zM5.5 41.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 73.5h15v11h-15v-11zm1 1v9h13v-9h-13zM22.5 73.5h15v11h-15v-11zm1 1v9h13v-9h-13zM5.5 23.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 49.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 57.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 65.5h32v6h-32v-6zm1 1v4h30v-4h-30zM5.5 9.5h15v12h-15v-12zm1 1v10h13v-10h-13zM22.5 9.5h15v12h-15v-12zm1 1v10h13v-10h-13z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-post-24':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41zm4 7h32v2h-32v-2zm0 4h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 4h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 5h32v4h-32v-4zm1 1v2h30v-2h-30zm-1 5h32v4h-32v-4zm1 1v2h30v-2h-30zm-1 5h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 4h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 4h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 4h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 5h32v4h-32v-4zm1 1v2h30v-2h-30zm-1 4h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 4h15v4h-15v-4zm1 1v2h13v-2h-13zm16-1h15v4h-15v-4zm1 1v2h13v-2h-13zm-18 4h32v4h-32v-4zm1 1v2h30v-2h-30zm-1 4h15v8h-15v-8zm1 1v6h13v-6h-13zm16-1h15v8h-15v-8zm1 1v6h13v-6h-13z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-fresh':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41zm4 8h33v16h-33v-16zm1 1v14h31v-14h-31zm-1 18h33v17h-33v-17zm1 1v15h31v-15h-31zm-1 19h33v16h-33v-16zm1 1v14h31v-14h-31zm-1 18h33v16h-33v-16zm1 1v14h31v-14h-31z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-laundry':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41zm4 8h14v33h-14v-33zm1 1v31h12v-31h-12zm18-1h14v33h-14v-33zm1 1v31h12v-31h-12zm-20 35h14v37h-14v-37zm1 1v35h12v-35h-12zm18-1h14v37h-14v-37zm1 1v35h12v-35h-12z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-burger':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41zm4 8h33v21h-33v-21zm1 1v19h31v-19h-31zm-1 25h33v6h-33v-6zm1 1v4h31v-4h-31zm-1 15h33v6h-33v-6zm1 1v4h31v-4h-31zm-1 10h33v21h-33v-21zm1 1v19h31v-19h-31z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-vending-soft':
        return (
          <svg viewBox="0 0 44 92" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M.5.5h43v91H.5V.5zm1 1v89h41v-89h-41zm4 8h32v21h-32v-21zm1 1v19h30v-19h-30zm-1 25h32v21h-32v-21zm1 1v19h30v-19h-30zm-1 25h32v21h-32v-21zm1 1v19h30v-19h-30z" clipRule="evenodd"/>
          </svg>
        );

      case 'stack-vending-cold':
        return (
          <svg viewBox="0 0 28 58" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#fff" fillRule="evenodd" d="M0 0h27.355v57.89H0V0zm.636.636v56.617h26.082V.637H.636zm2.545 5.09h20.357v13.358H3.18V5.725zm.636.636v12.086H22.9V6.361H3.817zM3.18 22.265h20.357v13.36H3.18v-13.36zm.636.636v12.087H22.9V22.901H3.817zM3.18 38.805h20.357v13.36H3.18v-13.36zm.636.636v12.087H22.9V39.441H3.817z" clipRule="evenodd"/>
            <path fill="#fff" d="M10.407 14.497l.755-.437-.637-.38c-.103-.049-.135-.187-.076-.288.059-.103.187-.125.284-.069l.85.498 1.353-.786-1.352-.778-.85.497a.166.166 0 0 1-.1.03.23.23 0 0 1-.185-.098c-.057-.103-.027-.24.076-.288l.637-.38-.755-.437a.213.213 0 0 1-.076-.288.21.21 0 0 1 .289-.076l.759.44.011-.76a.21.21 0 0 1 .21-.212c.117 0 .209.102.208.212L11.8 11.9l1.344.779v-1.58l-.846-.483a.214.214 0 0 1-.084-.288.204.204 0 0 1 .285-.076l.645.364v-.862c0-.117.095-.212.213-.212s.209.095.209.212v.87l.668-.372a.202.202 0 0 1 .281.076.21.21 0 0 1-.08.288l-.87.49v1.565l1.375-.793-.007-.98c-.012-.11.087-.213.205-.213h.007c.11 0 .208.095.21.212l.007.737.74-.425a.205.205 0 0 1 .285.076c.056.105.027.229-.076.289l-.748.432.653.392c.103.049.135.187.072.288a.195.195 0 0 1-.178.099.205.205 0 0 1-.11-.031l-.855-.505-1.37.786 1.37.794.855-.506c.102-.06.235-.031.288.07a.213.213 0 0 1-.072.288l-.653.387.748.436c.103.06.136.187.076.29-.042.07-.099.106-.178.106a.381.381 0 0 1-.107-.031l-.74-.425-.008.737a.211.211 0 0 1-.209.208h-.007a.208.208 0 0 1-.205-.216l.007-.98-1.374-.785v1.556l.87.49c.103.058.14.185.08.288-.043.072-.1.107-.18.107a.203.203 0 0 1-.102-.024l-.668-.379v.885a.206.206 0 0 1-.209.209.21.21 0 0 1-.213-.21v-.87l-.645.365a.198.198 0 0 1-.099.024.198.198 0 0 1-.186-.107.21.21 0 0 1 .084-.288l.846-.475v-1.58l-1.344.779.008.995a.211.211 0 0 1-.209.216.207.207 0 0 1-.209-.208l-.011-.764-.76.445a.223.223 0 0 1-.11.022.195.195 0 0 1-.178-.099.209.209 0 0 1 .076-.288z"/>
          </svg>
        );

      default:
        return null;
    }
  }
}


Icon.propTypes = {
  type: string.isRequired,
}


export default Icon;
