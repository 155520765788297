// # Core
import React, { PureComponent } from 'react';
import { string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';
import { updateTerminalLocationId } from '../../redux/actions';

// # Instruments
import { getUniqueID } from '../../helpers';
import { locationsList } from '../../initialData';


class SettingsLocationColumn extends PureComponent {
  onLocationButtonClick = (index) => {
    const { dispatch } = this.props;
    return dispatch(updateTerminalLocationId(index));
  }

  renderLocation = (location) => {
    const { terminalLocationId } = this.props;
    const { id, title } = locationsList[location];

    return (
      <div
        key={getUniqueID()}
        className={`settings__option${terminalLocationId === id ? ' is-active' : ''}`}
        onClick={() => this.onLocationButtonClick(id)}
      >
        <div className="settings__option-circle"></div>
        <div className="settings__option-title">{title}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="settings__column settings__column--location">
        <div className="settings__title">1. Локация вашего устройства</div>

        <div className="settings__options">
          {Object.keys(locationsList).map((location) => this.renderLocation(location))}
        </div>
      </div>
    );
  }
}


SettingsLocationColumn.propTypes = {
  terminalLocationId: string.isRequired,
}


const mapStateToProps = (state) => {
  const { terminalLocationId } = state.terminalData;

  return {
    terminalLocationId,
  };
};


export default connect(mapStateToProps)(SettingsLocationColumn);
