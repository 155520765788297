// # Core
import React, { Component } from 'react';
import axios from 'axios';

// # Redux
import { connect } from 'react-redux';
import { updatePopupVisibility } from '../../redux/actions';

// # Instruments
import { locationsList, complectationsList, terminalThemesList, stacksConfigurations } from '../../initialData.js';


class Popup extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      email: '',
      phone: '',
      isCheckedRecall: false,
      isCheckedAgreement: false,
      nameInputError: '',
      emailInputError: '',
      phoneInputError: '',
      formError: '',
      successMessage: '',
    }
  }

  closePopup = () => {
    const { dispatch } = this.props;
    return dispatch(updatePopupVisibility(false));
  }

  onHandleInputChange = (event, inputName, inputErrorName) => {
    const { value } = event.target;
    return this.setState({ [inputName]: value });
  }

  onHandleRecallChange = () => {
    return this.setState({ isCheckedRecall: !this.state.isCheckedRecall });
  }

  onHandleAgreementChange = () => {
    return this.setState({ isCheckedAgreement: !this.state.isCheckedAgreement });
  }

  isFormValid = () => {
    const { name, email, phone, isCheckedRecall, isCheckedAgreement } = this.state;

    let newState = {
      nameInputError: '',
      emailInputError: '',
      phoneInputError: '',
      formError: '',
      successMessage: '',
    }

    let isValid = true;

    if (name.length === 0) {
      newState.nameInputError = 'Поле не должно быть пустым';
      isValid *= false;
    }

    if (email.length === 0) {
      newState.emailInputError = 'Поле не должно быть пустым';
      isValid *= false;
    } else {
      const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
      if (!emailRegExp.test(String(email).toLowerCase())) {
        newState.emailInputError = 'Не верно введен email';
        isValid *= false;
      }
    }

    if (phone.length === 0) {
      if (isCheckedRecall) {
        newState.phoneInputError = 'Поле не должно быть пустым';
        isValid *= false;
      }
    } else {
      if (phone.trim().length < 8) {
        newState.phoneInputError = 'Не верно введен номер телефона';
        isValid *= false;
      }
    }

    if (!isCheckedAgreement) {
      newState.formError = 'Без Вашего согласия на обработку персональных данных, мы не можем отправить Вам конфигурацию на почту';
      isValid *= false;
    }

    this.setState({...this.state, ...newState});
    return isValid;
  }

  onSubmitClick = async () => {
    const { name, email, phone, isCheckedRecall } = this.state;
    const {
      terminalStacksList,
      terminalLocationId,
      terminalComplectationId,
      terminalTheme,
      terminalRunline,
      terminalLogo,
    } = this.props;

    if (this.isFormValid()) {
      return await axios.post(`http://logs-01.loggly.com/inputs/${atob('NTc4ODA3NWMtOWI0OS00ZDlhLWJmZjQtNWQ0MDJlOThjM2Vl')}/tag/http/`, {
        userName: name, // Имя юзера
        userEmail: email, // Имейл юзера
        userPhoneNumber: phone, // Телефон юзераа
        userWaitForConsultantCall: isCheckedRecall, // Ожидает связи с оператором
        terminalLocation: locationsList[terminalLocationId].title, // Локация для установки
        terminalComplectation: complectationsList[terminalComplectationId].name, // Комплектация (название)
        terminalConfigurationInOrder: terminalStacksList.map(stack => stacksConfigurations[stack.type].title), // Комплектация (стеки по порядку)
        terminalTheme: terminalThemesList[terminalTheme].title, // Наименование темы почтомата
        terminalRunline, // Текст бегущей строки
        terminalLogo: (terminalLogo.name) ? "Есть логотип" : "Без логотипа", // Наличие логотипа
      }).then((res) => {
        const { response } = res.data;

        if (response) {
          return this.setState({
            name: '',
            email: '',
            phone: '',
            isCheckedRecall: false,
            isCheckedAgreement: false,
            nameInputError: '',
            emailInputError: '',
            phoneInputError: '',
            formError: '',
            successMessage: 'Конфигурация отправленна менеджеру на обработку. Ожидайде, пожалуйста, сообщения на почту в ближайшее время.',
          });
        }
      });
    }
  }

  render() {
    const {
      name,
      email,
      phone,
      formError,
      successMessage,
      nameInputError,
      emailInputError,
      phoneInputError,
      isCheckedRecall,
      isCheckedAgreement,
    } = this.state;

    return (
      <div className="popup">
        <div className="popup__exit" onClick={this.closePopup} />
        <div className="popup__window">
          <div className="popup__cross" onClick={this.closePopup} />

          <div className="popup__title">Отправить конфигурацию конструктора на почту</div>

          <label className="popup__input-container">
            <span className="popup__input-title">Как к Вам обращаться?</span>
            <input className="popup__input" type="text" value={name} onChange={(event) => this.onHandleInputChange(event, 'name')} />
            {
              nameInputError.length > 0
                ? <span className="popup__input-error">{nameInputError}</span>
                : null
            }
          </label>

          <label className="popup__input-container">
            <span className="popup__input-title">Введите email</span>
            <input className="popup__input" type="email" value={email} onChange={(event) => this.onHandleInputChange(event, 'email')} />
            
            {
              emailInputError.length > 0
                ? <span className="popup__input-error">{emailInputError}</span>
                : null
            }
          </label>

          <label className="popup__input-container">
            <span className="popup__input-title">Введите номер телефона</span>
            <input className="popup__input" type="tel" value={phone} onChange={(event) => this.onHandleInputChange(event, 'phone')} />
            
            {
              phoneInputError.length > 0
                ? <span className="popup__input-error">{phoneInputError}</span>
                : null
            }
          </label>

          <div className="popup__checkboxes">
            <label className="popup__checkbox">
              <input type="checkbox" value={isCheckedRecall} checked={isCheckedRecall} onChange={this.onHandleRecallChange}/>
              <span className="popup__checkbox-title">Я хочу получить консультацию менеджера</span>
            </label>

            <label className="popup__checkbox">
              <input type="checkbox" value={isCheckedAgreement} checked={isCheckedAgreement} onChange={this.onHandleAgreementChange}/>
              <span className="popup__checkbox-title">Я даю согласие на обработку моих персональных данных</span>
            </label>
          </div>

          <div className="popup__button" onClick={this.onSubmitClick}>Отправить</div>

          {
            formError.length > 0
              ? <div className="popup__error">{formError}</div>
              : null
          }

          {
            successMessage.length > 0
              ? <div className="popup__success">{successMessage}</div>
              : null
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    isVisiblePopup,
    terminalStacksList,
    terminalLocationId,
    terminalComplectationId,
    terminalTheme,
    terminalRunline,
    terminalLogo,
   } = state.terminalData;

  return {
    isVisiblePopup,
    terminalStacksList,
    terminalLocationId,
    terminalComplectationId,
    terminalTheme,
    terminalRunline,
    terminalLogo,
  };
}


export default connect(mapStateToProps)(Popup);