// # Core
import React, { PureComponent } from 'react';
import { arrayOf, objectOf, oneOfType, string, number } from 'prop-types';

// # Redux
import { connect } from 'react-redux';
import { updateTerminalStacksList, updateTerminalComplectationId, updateTerminalHighlitedStackType } from '../../redux/actions';

// # Components
import Icon from '../Icon';

// # Instruments
import { stacksConfigurations } from '../../initialData';


class SettingsStack extends PureComponent {
  constructor() {
    super();

    this.state = {
      count: 0,
      creationNumber: 0,
    }
  }

  componentDidMount() {
    const currentsStacksCount = this.getStacksCount();
    return this.setState({ count: currentsStacksCount, creationNumber: currentsStacksCount });
  }

  componentDidUpdate() {
    const { count } = this.state;
    const currentsStacksCount = this.getStacksCount();
    
    if (count !== currentsStacksCount) {
      return this.setState({ count: currentsStacksCount, creationNumber: currentsStacksCount });
    }
  }

  getStacksCount = () => {
    const { terminalStacksList, stackType } = this.props;

    return terminalStacksList.filter((stack) => {
      return stack.type === stackType;
    }).length;
  }

  getStackIndexToRemove = (stackType, terminalStacksList) => {
    let maxOrderNumber = 0;
    let stackIndex = 0;
  
    for (let i = 0; i < terminalStacksList.length; i++) {
      if (
        terminalStacksList[i].type === stackType &&
        terminalStacksList[i].creationNumber >= maxOrderNumber
      ) {
        stackIndex = i;
        maxOrderNumber = terminalStacksList[i].creationNumber;
      }
    }

    return stackIndex;
  }

  onPlusButtonClick = () => {
    const { dispatch, stackType, terminalStacksList } = this.props;
    const { creationNumber } = this.state;

    if (terminalStacksList.length < 16) {
      dispatch(updateTerminalStacksList([...terminalStacksList, { type: stackType, creationNumber }]));
      dispatch(updateTerminalComplectationId('custom'));
      return this.setState({ creationNumber: creationNumber + 1 });
    }
  }

  onMinusButtonClick = () => {
    const { stackType, terminalStacksList, dispatch } = this.props;
    const stackIndexToRemove = this.getStackIndexToRemove(stackType, terminalStacksList);

    if (terminalStacksList.length > 2 && stackIndexToRemove !== null) {
      const newTerminalStacksArray = terminalStacksList.filter((_, index) => index !== stackIndexToRemove);

      dispatch(updateTerminalComplectationId('custom'));
      return dispatch(updateTerminalStacksList(newTerminalStacksArray));
    }
  }

  toggleHighlightStacks = (stackType) => {
    const { dispatch, terminalHighlitedStackType } = this.props;
    return dispatch(updateTerminalHighlitedStackType(terminalHighlitedStackType === stackType ? '' : stackType));
  }

  render() {
    const { count } = this.state;
    const { terminalStacksList, terminalHighlitedStackType, stackType } = this.props;
    const { title, tooltip, icon } = stacksConfigurations[stackType];

    return (
      <div className="settings__stack">
        <div className="settings__stack-head">
          {
            (tooltip) ? (
              <div className="settings__stack-tooltip">
                <Icon type="info" />
                <div className="settings__stack-tooltip-text">{tooltip}</div>
              </div>
            ) : null
          }

          <div
            className={`settings__stack-icon${terminalHighlitedStackType === stackType ? ' is-active' : ''}`}
            onMouseEnter={() => this.toggleHighlightStacks(stackType)}
            onMouseLeave={() => this.toggleHighlightStacks(stackType)}
          >
            <Icon type={icon} />
          </div>

          <div className="settings__stack-counter">
            <div
              className={`settings__stack-plus ${terminalStacksList.length === 16 || stackType === 'control' ? ' is-disabled' : ''}`}
              onClick={this.onPlusButtonClick}
            >+</div>

            <div className="settings__stack-count">{count}</div>

            <div
              className={`settings__stack-minus ${count === 0 || terminalStacksList.length === 2 || stackType === 'control' ? ' is-disabled' : ''}`}
              onClick={this.onMinusButtonClick}
            >-</div>
          </div>
        </div>

        <div className="settings__stack-title" dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    )
  }
}


SettingsStack.propTypes = {
  stackType: string.isRequired,
  terminalStacksList: arrayOf(objectOf(oneOfType([string, number]))).isRequired,
  terminalHighlitedStackType: string.isRequired,
}


const mapStateToProps = (state) => {
  const { terminalStacksList, terminalHighlitedStackType } = state.terminalData;

  return {
    terminalStacksList,
    terminalHighlitedStackType,
  };
};


export default connect(mapStateToProps)(SettingsStack);
