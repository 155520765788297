// # Core
import React, { PureComponent } from 'react';
import { string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';

// # Components
import TerminalStacks from './TerminalStacks';
import TerminalRunline from './TerminalRunline';
import { DragDropContext } from 'react-beautiful-dnd';


class Terminal extends PureComponent {
  render() {
    const { terminalTheme } = this.props;

    return (
      <div className="u-center">
        <div className={`terminal${terminalTheme !== '' ? ' terminal--' + terminalTheme : ''}`}>
          <div className="terminal__top-bar"></div>
          <TerminalRunline />

          <DragDropContext onDragEnd={this.onDragEnd}>
            <TerminalStacks />
          </DragDropContext>

          <div className="terminal__bottom-bar"></div>
        </div>
      </div>
    );
  }
}


Terminal.propTypes = {
  terminalTheme: string.isRequired,
}


const mapStateToProps = (state) => {
  const { terminalTheme } = state.terminalData;

  return {
    terminalTheme,
  };
};


export default connect(mapStateToProps)(Terminal);
