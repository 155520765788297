// # Core
import React, { Component } from 'react';
import { string, bool } from 'prop-types';

// # Redux
import { connect } from 'react-redux';
import { updatePopupVisibility } from '../../redux/actions';

// # Components
import Terminal from '../../components/Terminal';
import Settings from '../../components/Settings';
import Popup from '../../components/Popup';

// # Instruments
import { locationsList } from '../../initialData';


class App extends Component {
  onSendButtonClick = () => {
    const { dispatch } = this.props;
    return dispatch(updatePopupVisibility(true));
  }

  render() {
    const { terminalLocationId, isPopupVisible } = this.props;

    return (
      <div className="constructor">
        <div className="constructor__inner" style={{ backgroundImage: `url(/images/locations/${locationsList[terminalLocationId].image})` }}>
          <div className="constructor__title">Конструктор устройств Omnic</div>
          <div className="constructor__subtitle">Создай свой идеальный набор для бизнеса</div>

          <Terminal />
          <Settings />
        </div>

        <div className="constructor__action">
          <div className="constructor__action-title">Отправить конфигурацию постомата на почту</div>
          <div className="constructor__action-button" onClick={this.onSendButtonClick}>Отправить</div>
        </div>

        {isPopupVisible ? <Popup /> : null}
      </div>
    );
  }
}


App.propTypes = {
  terminalLocationId: string.isRequired,
  isPopupVisible: bool.isRequired,
};


const mapStateToProps = (state) => {
  const { terminalLocationId, isPopupVisible } = state.terminalData;

  return {
    terminalLocationId,
    isPopupVisible,
  };
};


export default connect(mapStateToProps)(App);
