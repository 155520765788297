// # Core
import React, { Component } from 'react';
import { arrayOf, objectOf, oneOfType, number, string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';
import { updateTerminalStacksList } from '../../redux/actions';

// # Components
import { Draggable } from 'react-beautiful-dnd';
import Icon from '../Icon';

// # Instruments
import { getUniqueID } from '../../helpers';
import { stacksConfigurations } from '../../initialData';


class TerminalStack extends Component {
  removeStack = () => {
    const { index, dispatch, terminalStacksList } = this.props;
    let newTerminalStacksList = terminalStacksList.filter((_, i) => {
      return i !== index;
    });

    return dispatch(updateTerminalStacksList(newTerminalStacksList));
  }

  renderControlStack = (provided) => {
    const { stackType, terminalHighlitedStackType, terminalLogo } = this.props;

    return (
      <div
        className={`terminal__stack terminal__controls${terminalHighlitedStackType && terminalHighlitedStackType !== stackType ? ' is-unhighlited' : ''}`}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className="terminal__cell terminal__controls-top">
          {
            terminalLogo.path.length > 0 ? (
              <img src={terminalLogo.path} alt="Logo"/>
            ) : null
          }
        </div>
        <div className="terminal__cell terminal__controls-middle">
          <div className="terminal__controls-screen"></div>

          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="terminal__cell terminal__controls-bottom">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="terminal__stack-buttons">
          <div className="terminal__stack-button">
            <Icon type="movement-arrow" />
          </div>
        </div>
      </div>
    );
  }

  renderDefaultStack = (provided) => {
    const {
      cellsNumber, stackType, terminalHighlitedStackType, terminalStacksList,
    } = this.props;

    return (
      <div
        className={`terminal__stack terminal__stack--${stackType}${terminalHighlitedStackType && terminalHighlitedStackType !== stackType ? ' is-unhighlited' : ''}`}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {
          Array(cellsNumber).fill('').map(_ => (
            <div className="terminal__cell" key={getUniqueID()}></div>
          ))
        }

        {/* <div className="terminal__stack-marker">{stacksConfigurations[stackType].marker}</div> */}

        <div className="terminal__stack-buttons">
          <div className="terminal__stack-button">
            <Icon type="movement-arrow" />
          </div>
          {
            terminalStacksList.length > 2 ? (
              <div className="terminal__stack-button" onClick={this.removeStack}>
                <Icon type="trash-bucket" />
              </div>
            ) : null
          }
        </div>
      </div>
    )
  }

  render() {
    const { stackType, index } = this.props;

    return (
      <Draggable draggableId={`${stackType}-${index}`} index={index}>
        {(provided) => (stackType === 'control') ? this.renderControlStack(provided) : this.renderDefaultStack(provided)}
      </Draggable>
    )
  }
}


TerminalStack.propTypes = {
  index: number.isRequired,
  cellsNumber: number,
  stackType: string.isRequired,
  terminalStacksList: arrayOf(objectOf(oneOfType([string, number]))).isRequired,
  terminalHighlitedStackType: string.isRequired,
  terminalLogo: objectOf(string).isRequired,
};


const mapStateToProps = (state) => {
  const { terminalHighlitedStackType, terminalStacksList, terminalLogo } = state.terminalData;

  return {
    terminalHighlitedStackType,
    terminalStacksList,
    terminalLogo,
  };
};


export default connect(mapStateToProps)(TerminalStack);
