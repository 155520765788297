// # Redux
import { combineReducers } from 'redux';

// # Reducers
import terminalData from './terminalData';


export default combineReducers({
  terminalData,
});
