// # Core
import React, { PureComponent } from 'react';

// # Components
import SettingsLocationColumn from './SettingsLocationColumn';
import SettingsComplectationColumn from './SettingsComplectationColumn';
import SettingsStacksColumn from './SettingsStacksColumn';
import SettingsThemeColumn from './SettingsThemeColumn';


class Settings extends PureComponent {
  render() {
    return (
      <div className="settings">
        <div className="settings__content">
          <SettingsLocationColumn />
          <SettingsComplectationColumn />
          <SettingsStacksColumn />
          <SettingsThemeColumn />
        </div>
      </div>
    );
  }
}

export default Settings;
